<template>
	<div>
		<div class="card p-2">
			<div class="table-responsive mb-0">
				<b-table-simple sticky-header class="table mb-0" :style="{ minHeight: '70vh' }">
					<b-thead>
						<b-tr>
							<b-th colspan="1" class="bg-transparent border-0"> </b-th>
							<!-- <b-th colspan="3" class="text-center bg-white">
								<div class="bg-info">
									<p class="text-white font-weight-bolder mb-0">Fechas</p>
								</div>
								
							</b-th> -->

							<b-th colspan="2" class="p-25 bg-info bg-darken-1 text-white text-center">
								<div class="">
									<p class="mb-0">Fechas</p>
								</div>
							</b-th>
							<b-th colspan="4" class="bg-transparent border-0"> </b-th>
						</b-tr>
						<b-tr>
							<b-th v-for="(title, key) in headTable" :key="key" class="text-nowrap">
								<div
									class="d-flex align-items-center justify-content-center cursor-pointer"
									v-if="title.visible"
								>
									<p class="mb-0 mr-1">{{ title.title }}</p>
								</div>
							</b-th>
						</b-tr>
					</b-thead>
					<b-tbody>
						<b-tr class="text-center" v-for="(item, index) in inventories" :key="index">
							<template v-if="inventories.length > 0">
								<b-td class="">
									<p class="mb-0 font-weight-bolder">{{ item.code }}</p>
								</b-td>

								<b-td class="">
									<div class="d-flex">
										<p class="mb-0" v-if="item.start_date">
											{{ item.start_date | myGlobalDayShort }}
										</p>
										<p class="mb-0" v-else>---</p>

										<FeatherIcon
											v-if="[1, 2].includes(item.status_id)"
											@click="
												modalUpdateDate.show = true;
												modalUpdateDate.title = `Actualizar fecha / ${item.code}`;
												modalUpdateDate.inventory_id = item.id;
												modalUpdateDate.value = item.start_date;
											"
											icon="Edit3Icon"
											size="18"
											class="icon text-dark ml-50 cursor-pointer"
										/>
									</div>
								</b-td>

								<b-td class="">
									<p class="mb-0" v-if="item.end_date">{{ item.end_date | myGlobalDayShort }}</p>
									<p class="mb-0" v-else>---</p>
								</b-td>

								<b-td class="" :style="{ width: '400px' }">
									<div class="d-flex align-items-center justify-content-center">
										<p
											v-if="item.comment"
											class="mb-0"
											v-b-tooltip.hover
											:title="item.comment ? item.comment : null"
										>
											{{
												item.comment.length > 50
													? `${item.comment.slice(0, 50)}...`
													: item.comment
											}}
										</p>
										<p v-else>---</p>

										<FeatherIcon
											@click="
												modalUpdateComment.show = true;
												modalUpdateComment.title = `Actualizar comentario / ${item.code}`;
												modalUpdateComment.inventory_id = item.id;
												modalUpdateComment.value = item.comment;
											"
											icon="Edit3Icon"
											size="18"
											class="icon text-dark ml-1 cursor-pointer"
										/>
									</div>
								</b-td>

								<b-td class="text-center">
									<p
										:style="{}"
										class="mb-0 font-weight-bolder"
										@click="item.status_id != 3 && addPlates(item)"
										:class="[
											item.status_id != 3 ? 'text-primary cursor-pointer' : 'text-secondary',
										]"
									>
										{{ item.value.length }}
									</p>
								</b-td>

								<b-td class="">
									<p
										class="mb-0 font-weight-bolder"
										:class="[
											item.status_id != 3 ? 'text-success cursor-pointer' : 'text-secondary',
										]"
										@click="item.status_id != 3 && item.match != 0 && setActionInventory(item, 0)"
									>
										{{ item.counters.match }}
									</p>
								</b-td>

								<b-td class="">
									<p
										class="mb-0 font-weight-bolder"
										:class="[
											item.status_id == 1 && item.counters.missing != 0
												? 'text-danger cursor-pointer'
												: 'text-secondary',
										]"
										@click="
											item.status_id == 1 &&
												item.counters.missing != 0 &&
												setActionInventory(item, 1)
										"
									>
										{{ item.counters.missing }}
									</p>
								</b-td>

								<b-td class="">
									<p
										class="mb-0 font-weight-bolder"
										:class="[
											item.status_id == 1 && item.counters.spare != 0
												? 'text-danger cursor-pointer'
												: 'text-secondary',
										]"
										@click="
											item.status_id == 1 &&
												item.counters.spare != 0 &&
												setActionInventory(item, 2)
										"
									>
										{{ item.counters.spare }}
									</p>
								</b-td>

								<b-td class="">
									<p class="mb-0">{{ item.name }}</p>
									<span class="font-small-3">{{ item.created_at | myGlobalDayShort }}</span>
								</b-td>

								<b-td class="">
									<FeatherIcon
										v-b-tooltip.hover
										title="Finalizar inventario"
										v-if="item.status_id != 3"
										@click="finishedInventory(item)"
										icon="CheckSquareIcon"
										size="20"
										class="icon text-secondary cursor-pointer"
									/>
									<FeatherIcon
										v-else
										v-b-tooltip.hover
										title="Finalizado"
										icon="CheckSquareIcon"
										size="20"
										class="icon text-success"
									/>
								</b-td>
							</template>
						</b-tr>
						<b-tr v-if="inventories.length == 0">
							<b-td colspan="10" class="text-center">
								<p class="mb-0 font-weight-bold">Sin inventarios encontrados</p>
							</b-td>
						</b-tr>
					</b-tbody>
				</b-table-simple>
			</div>
		</div>

		<b-sidebar
			v-model="sidebarCrearInventario.show"
			:title="sidebarCrearInventario.title"
			left
			width="90%"
			sidebar-class="sidebar-class"
			body-class="p-0 bg-white"
			scrollable
			@hidden="hideModal"
		>
			<CreateInventorySpecimens
				@refresh="refresh"
				v-if="sidebarCrearInventario.show"
				:data="dataSpecimens"
				:module="module"
				:commentAux="sidebarCrearInventario.comment"
			/>
		</b-sidebar>

		<b-modal hide-footer v-model="modalUpdateComment.show" :title="modalUpdateComment.title">
			<!-- <h2>Hola mundo</h2> -->
			<!-- {{ modalUpdateComment.inventory_id }} -->

			<b-form-group label="Agregar comentario">
				<b-form-textarea
					v-model="modalUpdateComment.value"
					placeholder="Agregar comentario"
					rows="3"
					max-rows="6"
				></b-form-textarea>
			</b-form-group>

			<div class="d-flex align-items-center justify-content-end">
				<button @click="updateComment" class="btn btn-primary">Guardar</button>
			</div>
		</b-modal>

		<b-modal hide-footer v-model="modalUpdateDate.show" :title="modalUpdateDate.title">
			<b-form-group label="Fecha">
				<flat-pickr
					v-model="modalUpdateDate.value"
					class="form-control bg-white"
					placeholder="Seleccionar fechas"
					dateFormat="m-Y-d"
				/>
			</b-form-group>

			<div class="d-flex align-items-center justify-content-end">
				<button @click="updateDate" class="btn btn-primary">Guardar</button>
			</div>
		</b-modal>
	</div>
</template>

<script>
import inventoryService from "@/services/inventory.service";
import moment from "moment";
import { mapState, mapActions, mapMutations } from "vuex";
import CreateInventorySpecimens from "../../specimens/components/CreateInventorySpecimens.vue";
// import CreateInventorySpecimens from "../components/CreateInventorySpecimens.vue";
// CreateInventorySpecimens
// import fakeSpecimens from "../fake_specimens.js";

export default {
	name: "ViewInventoryChicks",
	components: {
		CreateInventorySpecimens,
	},
	data() {
		return {
			headTable: [
				{ key: "code", title: "Codigo", visible: true },
				{ key: "fecha", title: "Inicio", visible: true },
				{ key: "fecha", title: "Fin", visible: true },
				{ key: "comment", title: "Comentario", visible: true },
				{ key: "plates", title: "Ingresadas", visible: true },
				{ key: "match", title: "Encontradas", visible: true },
				// { key: "unmatch", title: "#No coinciden", visible: true },
				{ key: "missing", title: "No encontradas", visible: true },
				{ key: "leftOver", title: "Restantes", visible: true },

				{ key: "usuario", title: "Creado por", visible: true },
				{ key: "actions", title: "Acciones", visible: true },
			],
			sidebarCrearInventario: {
				show: false,
				title: null,
				data: [],
				comment: null,
			},
			dataSpecimens: [],
			modalUpdateComment: {
				show: false,
				title: null,
				inventory_id: null,
				value: null,
			},
			modalUpdateDate: {
				show: false,
				title: null,
				inventory_id: null,
				value: null,
			},
		};
	},
	computed: {
		...mapState("inventorySpecimens", ["inventories", "inventory"]),
		module() {
			return this.$route.meta.module;
		},
	},
	async mounted() {
		this.isPreloading();
		// this.SELECT_INVENTORY({
		// 	item: {
		// 		value: [],
		// 		start_date: null,
		// 	},
		// });
		// this.dataSpecimens = fakeSpecimens;
		await this.init();
		// console.log('module', this.$route.meta.module);
	},
	methods: {
		...mapActions("inventorySpecimens", ["getInventories"]),
		...mapMutations("inventorySpecimens", ["SELECT_INVENTORY", "UPDATE_INDEX_INVENTORY"]),
		async init() {
			this.isPreloading();
			await this.getInventories({ module: this.module });
			// this.isPreloading(false);
		},
		createInventory() {
			this.sidebarCrearInventario.show = true;
			this.sidebarCrearInventario.title = `Nuevo inventario - ${this.module == 1 ? "Ejemplares" : "Pollos"}`;
			this.SELECT_INVENTORY({
				item: {
					value: [],
				},
			});
		},
		setActionInventory(item, key) {
			const name = this.module == 1 ? `inventory-specimens-by-id-sr` : `inventory-chicks-by-id-sr`;
			this.SELECT_INVENTORY({ item });

			this.UPDATE_INDEX_INVENTORY({ index: key });

			this.$router.push({
				name,
				params: {
					inventory_id: item.id,
				},
				query: {
					match: key,
				},
			});
		},
		addPlates(item) {
			this.SELECT_INVENTORY({ item });
			this.sidebarCrearInventario.show = true;
			this.sidebarCrearInventario.title = `Inventario ${this.inventory.code}`;
			console.log("item", item);
			this.sidebarCrearInventario.comment = item.comment;
		},
		async processInventory(inv) {
			const { isConfirmed } = await this.showConfirmSwal();
			if (!isConfirmed) return;
			this.isPreloading();
			const { message } = await inventoryService.update({
				id: inv.id,
				status_id: 2,
			});
			this.isPreloading(false);
			this.showToast("success", "top-right", "Inventarios", "SuccessIcon", message);
			await this.init();
		},
		async finishedInventory(inv) {
			// if (inv.counters.spare != 0 && inv.counters.missing != 0) {
			// 	this.showToast(
			// 		"warning",
			// 		"top-right",
			// 		"Inventarios",
			// 		"WarningIcon",
			// 		"No se puede finalizar el inventario."
			// 	);
			// 	return;
			// }

			if (!(inv.counters.match == inv.value.length && inv.counters.missing == 0 && inv.counters.spare == 0)) {
				return this.showToast(
					"warning",
					"top-right",
					"Inventario",
					"WarningIcon",
					"No es posible finalizar este inventario."
				);
			}

			const { isConfirmed } = await this.showConfirmSwal();
			if (!isConfirmed) return;
			this.isPreloading();
			const { message } = await inventoryService.update({
				id: inv.id,
				status_id: 3,
				module: this.module,
				end_date: moment(new Date()).format("YYYY-MM-DD"),
			});
			this.isPreloading(false);
			this.showToast("success", "top-right", "Inventarios", "SuccessIcon", message);
			await this.init();
		},
		async refresh() {
			this.sidebarCrearInventario.show = false;
			await this.init();
		},
		hideModal() {
			this.SELECT_INVENTORY({
				item: {
					value: [],
				},
			});
		},
		async updateComment() {
			this.isPreloading();
			const { status, message } = await inventoryService.updateComment({
				comment: this.modalUpdateComment.value,
				id: this.modalUpdateComment.inventory_id,
			});
			if (status) {
				await this.init();
				this.showToast("success", "top-right", "Inventarios", "SuccessIcon", message);
				this.modalUpdateComment.show = false;
			}
			this.isPreloading(false);
		},
		async updateDate() {
			this.isPreloading();
			const { status, message } = await inventoryService.update({
				start_date: moment(this.modalUpdateDate.value).format("YYYY-MM-DD"),
				id: this.modalUpdateDate.inventory_id,
			});
			if (status) {
				// const { parent, child } = this.modalUpdateDate.ids;
				// this.UPDATE_COMMENT({ index: parent, key: child, comment: this.modalUpdateDate.value });
				this.showToast("success", "top-right", "Inventarios", "SuccessIcon", message);
				await this.init();

				this.modalUpdateDate.show = false;
			}
			this.isPreloading(false);
		},
	},
};
</script>
<style scoped lang="scss"></style>
